import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
// import { BreadcrumbBox } from '../../components/common/Breadcrumb';
// import CourseItemList from './components/CourseItemsList';
import FeesList from './components/FeesList';
import Footer from 'components/Footer';
import { Styles } from './styles/course.js';
// import { db, collection, query, limit, orderBy, startAfter, getDocs } from 'FirebaseConfig';
import { colors } from 'components/common/element/elements';

const Payments = () => {
    // const [achievements, setAchievements] = useState([]);
    // const [lastVisible, setLastVisible] = useState(null);
    // const [loading, setLoading] = useState(false);
    // const [loadingMore, setLoadingMore] = useState(false);
    // const [hasMore, setHasMore] = useState(true);
    // const [noMoreRecords, setNoMoreRecords] = useState(false);

    useEffect(() => {
        // fetchInitialData();
    }, []);

    // const fetchInitialData = async () => {
    //     setLoading(true);
    //     try {
    //         const achievmentsQuery = query(
    //             collection(db, 'achievement'),
    //             orderBy('ac_date', 'desc'),
    //             limit(5)
    //         );
    //         const querySnapshot = await getDocs(achievmentsQuery);
    //         const achievementsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //         setAchievements(achievementsData);
    //         setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    //         setLoading(false);
    //     } catch (error) {
    //         console.error("Error fetching achievements: ", error);
    //         setLoading(false);
    //     }
    // };

    // const fetchMoreData = async () => {
    //     setLoadingMore(true);
    //     try {
    //         const achievmentsQuery = query(
    //             collection(db, 'achievement'),
    //             orderBy('ac_date', 'desc'),
    //             startAfter(lastVisible),
    //             limit(5)
    //         );
    //         const querySnapshot = await getDocs(achievmentsQuery);
    //         const newAchievements = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //         setAchievements(prevAchievements => [...prevAchievements, ...newAchievements]);
    //         setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    //         setLoadingMore(false);
    //         if (querySnapshot.docs.length < 5) {
    //             setHasMore(false);
    //             setNoMoreRecords(true);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching more achievements: ", error);
    //         setLoadingMore(false);
    //     }
    // };

    const feeStructure = [
        {
            class: "NUR",
            admission_fees: "17,000",
            april_to_june: "7500 + 900 (Exam fees)",
            july_to_sept: "7500",
            oct_to_dec: "7500",
            jan_to_march: "7500"
        },
        {
            class: "LKG",
            admission_fees: "17,000",
            april_to_june: "7500 + 900 (Exam fees)",
            july_to_sept: "7500",
            oct_to_dec: "7500",
            jan_to_march: "7500"
        },
        {
            class: "UKG",
            admission_fees: "17,000",
            april_to_june: "7500 + 900 (Exam fees)",
            july_to_sept: "7500",
            oct_to_dec: "7500",
            jan_to_march: "7500"
        },
        {
            class: "Ist",
            admission_fees: "22,000",
            april_to_june: "8500 + 1500 (Exam fees)",
            july_to_sept: "8500",
            oct_to_dec: "8500",
            jan_to_march: "8500"
        },
        {
            class: "IInd",
            admission_fees: "22,000",
            april_to_june: "8500 + 1500 (Exam fees)",
            july_to_sept: "8500",
            oct_to_dec: "8500",
            jan_to_march: "8500"
        },
        {
            class: "IIIrd",
            admission_fees: "22,000",
            april_to_june: "8900 + 1500 (Exam fees)",
            july_to_sept: "8900",
            oct_to_dec: "8900",
            jan_to_march: "8900"
        },
        {
            class: "IVth",
            admission_fees: "22,000",
            april_to_june: "8900 + 1500 (Exam fees)",
            july_to_sept: "8900",
            oct_to_dec: "8900",
            jan_to_march: "8900"
        },
        {
            class: "Vth",
            admission_fees: "24,000",
            april_to_june: "9400 + 1500 (Exam fees)",
            july_to_sept: "9400",
            oct_to_dec: "9400",
            jan_to_march: "9400"
        },
        {
            class: "VIth",
            admission_fees: "24,000",
            april_to_june: "9400 + 1500 (Exam fees)",
            july_to_sept: "9400",
            oct_to_dec: "9400",
            jan_to_march: "9400"
        },
        {
            class: "VIIth",
            admission_fees: "24,000",
            april_to_june: "9400 + 1500 (Exam fees)",
            july_to_sept: "9400",
            oct_to_dec: "9400",
            jan_to_march: "9400"
        }
    ];


    return (
        <div className="main-wrapper course-page">
            {/* Header 2 */}
            <HeaderTwo />
            {/* Breadcrumb */}
            {/* <BreadcrumbBox title="Achievements" /> */}
            <Styles>
                {/* Course Grid */}
                <section className="course-list-area">
                    <div className="payment-area ">

                        <div className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-5  pt-5 _text-center'>Fees structure ( 2024 -2025 ) </h5>
                                <div className="course-items2 text-center">
                                    <FeesList Datas={feeStructure} />

                                </div>
                                <ul className='pl-5 pt-5'>

                                    <li className='pb-2' >
                                        Registration fees - Rs.370 Annually
                                    </li>
                                    <li className='pb-2' >
                                        Admission fees will be charged only once at the time of new admission
                                    </li>
                                    <li className='pb-2' >
                                        Document's Required:  Aadhar card of Mother, Father and child, Birth certificate of the child, 3 passport size photos of Mother, father and child along with one passport size family photo.
                                    </li>
                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>

                        <div className=" pt-5 pb-5 text-light" style={{ background: `${colors.bg1}` }}>
                            <Container>
                                <h5 className='payment-h5 pb-5 _text-center text-light'>Note </h5>
                                <div className="">
                                    <ul className='pl-2'>
                                        <li className='pb-2'>Money once remitted will not be refunded.
                                        </li>
                                        <li className='pb-2' >Cash is not accepted in School.
                                        </li>
                                        <li className='pb-2' >School fees are payable from 1st to 10th of April, July, October and January. In case 10th happens to be a Public Holiday, 11th will be the last day of the fee payable period.
                                        </li>
                                        <li className='pb-2' >
                                            Late fees payment will be taken after 11th of second month. Fine of Rs. 50 per month will be charged.

                                        </li>
                                        <li className='pb-2' >
                                            Fees can be paid -
                                            UPI	( QR Code Scan) or  App 
                                    
                                        </li>
                                        <li className='pb-2' >
                                            Fees can be paid 
                                            Cash Deposit also ( South Indian Bank )
                                            (Near St. Joseph’s Hospital Extention Counter, Mariam Nagar)
                                            (Bank Timing – 10:00am to 1:30pm)
                                    
                                        </li>
                                        <li className='pb-2' >
                                        DD will be accepted only at the time of admission in favour of ST. MARY’S CONVENT SCHOOL.

                                        </li>
                                    </ul>
                                </div>

                            </Container>
                        </div>
                        <div className='pt-5'>
                            <Container>

                                <h5 className='payment-h5 pb-5 pt-5 _text-center  '>Online Fees payment - QR code</h5>
                                <Row>

                                    <Col sm="12" lg="6">
                                        <div className="">
                                            <div className="w-full p">
                                                <img style={{ maxWidth: "400px", filter : "blur(10px)" }} className='w-100' src="/assets/payment/qr.webp" alt="" />
                                            </div>
                                        </div>

                                    </Col>
                                    <Col sm="12" lg="6">
                                        <div className="  ">

                                            <div className="">
                                                <p>
                                                    To facilitate a smooth and efficient payment process, St. Mary's Convent School offers a convenient QR code payment option. You can now make payments securely and instantly using your preferred mobile payment app.
                                                </p>
                                                <ul className='p-4'>
                                                    <li className='pb-2'>Open Your Payment App: Launch your preferred mobile payment application on your smartphone.</li>
                                                    <li className='pb-2' >Scan the QR Code: Use your app's QR code scanner to scan the QR code provided below.</li>
                                                    <li className='pb-2' >Confirm Payment: Verify the details and confirm the payment with us.</li>
                                                </ul>

                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>
                            </Container>
                        </div>





                    </div>
                </section>
            </Styles>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Payments;
