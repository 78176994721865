import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/mobileMenu.js";

function MobileMenu() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleMenuToggle = (index) => {
        setActiveMenu(activeMenu === index ? null : index);
    };

    return (
        <Styles>
            {/* Mobile Menu */}
            <section className="mobile-menu-area">
                <Container>
                    <Row>
                        <Col md="0" sm="12">
                            <div className="mb-topbar d-flex justify-content-between">
                                {/* Optional Topbar Items */}
                            </div>
                            <div className="mb-logo-area d-flex justify-content-between">
                                <div className="mb-logo-box d-flex justify-content-between w-100">
                                    <div className="hm-button">
                                        <a onClick={toggleSidebar} id="mb-sidebar-btn">
                                            <i className="las la-bars"></i>
                                        </a>
                                    </div>
                                    <div className="mb-logo">
                                        <Link to={`${process.env.PUBLIC_URL}/`}>
                                            <img src={`${process.env.PUBLIC_URL}/assets/custom_images/header-logo.png`} alt="Logo" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Mobile Menu Sidebar */}
            <section className={`mb-sidebar ${isSidebarOpen ? "opened" : ""}`} id="mb-sidebar-body">
                <div className="mb-sidebar-heading d-flex justify-content-between">
                    <h5>Menu</h5>
                    <a onClick={toggleSidebar} id="close-mb-sidebar">
                        <i className="las la-times"></i>
                    </a>
                </div>
                <div className="mb-sidebar-menu">
                    {menuItems.map((menu, index) => (
                        <div className="mb-menu-item" key={index}>
                            <button
                                className={`mb-menu-button ${activeMenu === index ? "active" : ""}`}
                                onClick={() => handleMenuToggle(index)}
                            >
                                <p>{menu.title} <i className="las la-plus"></i></p>
                            </button>
                            <div
                                className={`mb-menu-content ${activeMenu === index ? "show" : ""}`}
                                style={{ maxHeight: activeMenu === index ? `${menu.items.length * 40}px` : "0" }}
                            >
                                <ul className="list-unstyled">
                                    {menu.items.map((item, subIndex) => (
                                        <li key={subIndex}>
                                            <Link to={`${process.env.PUBLIC_URL}${item.link}`}>{item.label}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Sidebar Overlay */}
            {isSidebarOpen && <div className="mb-sidebar-overlay" onClick={toggleSidebar} id="mb-sidebar-overlay"></div>}
        </Styles>
    );
}

// Define your menu items with titles and links
const menuItems = [
    {
        title: "Home",
        items: [{ label: "Home", link: "/" }],
    },
    {
        title: "About Us",
        items: [
            { label: "About Us", link: "/about#about" },
            { label: "Mission", link: "/about#mission" },
            { label: "Vision", link: "/about#vision" },
            { label: "Our Patroness", link: "/about#principal" },
            { label: "Preamble", link: "/about#about" },
            { label: "Aim & Objectives", link: "/about#objective" },
            { label: "Administration", link: "/about#administration" },
            { label: "Admissions", link: "/noticeboard/9T6F4JX4HWrVL1X8Yyp6" },
        ],
    },
    {
        title: "Rules",
        items: [
            { label: "Examination & Promotion", link: "/rules-examination" },
            { label: "Registration & Admission", link: "/rules-admission" },
            { label: "Withdrawal & Leave", link: "/rules-of-conduct#leave" },
            { label: "Rules of Conduct & Discipline", link: "/rules-of-conduct#dicipline" },
            { label: "School Uniform", link: "/rules-uniform" },
            { label: "School Timings", link: "/#timing" },
            { label: "Fees Structure", link: "/payments" },
        ],
    },
    {
        title: "Activities",
        items: [
            { label: "Sports & Activities", link: "/activities#sports" },
            { label: "School Band", link: "/activities#band" },
            { label: "Competitions", link: "/activities#competitions" },
            { label: "House System", link: "/activities#house" },
            { label: "Social & Community Initiatives", link: "/activities#social" },
        ],
    },
    {
        title: "Showcase",
        items: [
            { label: "Achievements", link: "/achievements" },
            { label: "Gallery", link: "/gallery" },
        ],
    },
    {
        title: "Information Hub",
        items: [
            { label: "Notice Board", link: "/noticeboard" },
            { label: "Downloads", link: "/prospectus#download" },
            { label: "Events", link: "/eventlist" },
        ],
    },
    {
        title: "Contact Us",
        items: [{ label: "Contact Us", link: "/contact" }],
    },
    {
        title: "Fees",
        items: [{ label: "Fees", link: "/payments" }],
    },
];

export default MobileMenu;
