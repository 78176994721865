import { colors } from "components/common/element/elements";
import styled from "styled-components";

export const Styles = styled.div`
    .gallery-page {
        .gallery-page-area {
            padding : 70px 0;
            .gallery-box {
                border-radius : 5px;
                overflow: hidden;
                margin-bottom: 30px;
                position: relative;
                height : 300px;
                img {
                    transition : all 0.3s ease;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 30px 0;
            }

            .btn1 {
                font-size : 14px;
                color     : #fff;
                background: ${colors.gr_bg};
                display   : inline-block;
                width     : 145px;
                height    : 40px;
                text-align: center;
                padding   : 11px;
                border-radius : 5px;
                cursor : pointer;

                &:hover {
                    background: ${colors.gr_bg2};
                }
            }
        }
    }
`;