import React, {  Fragment } from 'react';
// import Datas from '../../../data/course/item.json';
// import Pagination from './../../../components/Pagination';

const CourseItemList = (props) => {

    const { Datas = [] } = props

    return (
        <Fragment>
            {/* Course Item */}
            <div className="table-responsive">

                <table class="table table-striped border" style={{ minWidth: "500px" }}>
                    <thead>
                        <tr>
                            <th scope="col">CLASS </th>
                            <th scope="col">ADMISSION FEES</th>
                            <th scope="col">APRIL TO JUNE <br/> Ist QUARTER </th>
                            <th scope="col">JULY TO SEPT <br/> IInd QUARTER </th>
                            <th scope="col">OCT TO DEC <br/> IIIrd QUARTER </th>
                            <th scope="col">JAN TO MARCH <br/> IVth QUARTER
                            </th>
                        </tr>

                    </thead>
                    <tbody>

                        {
                            Datas.map((data, i) => (
                                <tr key={i}>
                                    <td>  {data.class} </td>
                                    <td>  {data.admission_fees} </td>
                                    <td>  {data.april_to_june} </td>
                                    <td>  {data.july_to_sept} </td>
                                    <td>  {data.oct_to_dec} </td>
                                    <td>  {data.jan_to_march} </td>
                                </tr>
                            ))
                        }
                    </tbody>


                </table>
                {/* <Col md="12" key={i}>
                    <div className="course-item ">

                        <div className="payment-content d-flex justify-content-between">
                            <div className="">
                                <h6 className="heading text-left">  {data.title} </h6>
                                <p className="desc text-left">{data.description}</p>
                            </div>
                            <div className="">
                                <div className="h text-success"> ₹ 2000 </div>
                            </div>
                            
                        </div>

                    </div>
                </Col> */}
            </div>


        </Fragment>
    )
}

export default CourseItemList
