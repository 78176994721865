import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from "./serviceWorker";

import "css/swiper.min.css"
import "css/animate.css"
// import "css/bootstrap.min.css"


ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();