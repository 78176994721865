import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import CourseItemList from './components/CourseItemsList';
import Footer from 'components/Footer';
import { Styles } from './styles/course.js';
import { db, collection, query, limit, orderBy, startAfter, getDocs } from 'FirebaseConfig';

const CourseList = () => {
    const [achievements, setAchievements] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [noMoreRecords, setNoMoreRecords] = useState(false);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        try {
            const achievmentsQuery = query(
                collection(db, 'achievement'),
                orderBy('ac_date', 'desc'),
                limit(5)
            );
            const querySnapshot = await getDocs(achievmentsQuery);
            const achievementsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAchievements(achievementsData);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching achievements: ", error);
            setLoading(false);
        }
    };

    const fetchMoreData = async () => {
        setLoadingMore(true);
        try {
            const achievmentsQuery = query(
                collection(db, 'achievement'),
                orderBy('ac_date', 'desc'),
                startAfter(lastVisible),
                limit(5)
            );
            const querySnapshot = await getDocs(achievmentsQuery);
            const newAchievements = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAchievements(prevAchievements => [...prevAchievements, ...newAchievements]);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setLoadingMore(false);
            if (querySnapshot.docs.length < 5) {
                setHasMore(false);
                setNoMoreRecords(true);
            }
        } catch (error) {
            console.error("Error fetching more achievements: ", error);
            setLoadingMore(false);
        }
    };

    return (
        <div className="main-wrapper course-page">
            {/* Header 2 */}
            <HeaderTwo />
            {/* Breadcrumb */}
            <BreadcrumbBox title="Achievements" />
            <Styles>
                {/* Course Grid */}
                <section className="course-list-area">
                    <Container>
                        {achievements.length > 0 ? <div className="course-items2 text-center">
                            <CourseItemList Datas={achievements} />
                            {loading && <div> Loading... </div>}
                            {loadingMore && <div> Loading more... </div>}
                            {!loading && !loadingMore && hasMore && (
                                <div className="text-center">
                                    <div
                                        style={{ maxWidth: "100px", margin: "auto" }}
                                        className="details-btn pointer"
                                        role="button"
                                        onClick={fetchMoreData}
                                    >
                                        Load more
                                    </div>
                                </div>
                            )}
                            {!hasMore && noMoreRecords && (
                                <div className="text-center">
                                    No more records to load
                                </div>
                            )}
                        </div>
                        :
                        <div style={{minHeight : "200px"}} className="pt-5 pb-5 w-100 d-flex justify-content-center" >
                            <div className=" " style={{opacity : "0.5"}}>
                                <img style={{  maxWidth : "100px", opacity : "0.5"}}   src="/assets/custom_images/achievments/icon_blank.png" alt='Blank' />
                                <div className="text-center pt-3  font-weight-bold">
                                    No Records
                                </div>
                            </div>
                        </div>
                        }
                    </Container>
                </section>
            </Styles>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default CourseList;
