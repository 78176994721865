import React,  { useEffect } from 'react';

import { useLocation } from "react-router-dom";

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { GlobalStyle } from "./components/common/styles/global.js";
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Contact from './pages/contact/Contact';
import PageNotFound from './pages/404/PageNotFound';


import HomeOne from './HomeOne';
import About from './pages/about/About';
import Gallery from './pages/gallery/Gallery';

import Achievements from './pages/courses/Achievments';
import Payments from './pages/courses/Payments';
import Prospectus from './pages/courses/Downloads';
import NoticeBoard from 'pages/courses/NoticeBoard';
import EventList from './pages/events/EventList';
import NoticeBoardDetailed from "./pages/courses/NoticeBoardDetailed";
import Rules_Admission from './pages/courses/Rules_Admission';
import Rules_Examination from './pages/courses/Rules_Examination';
import RulesOfConduct from './pages/courses/RulesOfConduct';
import RulesOfUniform from './pages/courses/Rules_uniform';
import Activities from './pages/courses/Activities';


// import HomeTwo from './HomeTwo';
// import CourseGrid from './pages/courses/CourseGrid';
// import CourseList from './pages/courses/CourseList';
// import CourseDetails from './pages/courses/CourseDetails';
// import Instructor from './pages/instructor/Instructors';
// import InstructorDetails from './pages/instructor/InstructorDetails';
// import Login from './pages/account/Login';
// import Register from './pages/account/Register';
// import Faq from './pages/faq/Faq';
// import ComingSoon from './pages/comingsoon/ComingSoon';
// import BlogClassic from './pages/blog/BlogClassic';
// import BlogGrid from './pages/blog/BlogGrid';
// import BlogDetails from './pages/blog/BlogDetails';
// import Product from './pages/shop/Products';
// import ProductDetails from './pages/shop/ProductDetails';
// import Cart from './pages/shop/Cart';





function App() {
    return (
        <Router>
            <GlobalStyle />
            <ScrollToTop />
            <Switch>
                <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={HomeOne} />

                <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={About} />
                <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} component={Gallery} />
                <Route path={`${process.env.PUBLIC_URL + "/achievements"}`} component={Achievements} />
                <Route path={`${process.env.PUBLIC_URL + "/eventlist"}`} component={EventList} />
                <Route path={`${process.env.PUBLIC_URL + "/prospectus"}`} component={Prospectus} />
                <Route path={`${process.env.PUBLIC_URL + "/payments"}`} component={Payments} />


                <Route path={`${process.env.PUBLIC_URL + "/noticeboard/:id"}`} component={NoticeBoardDetailed} />
                <Route path={`${process.env.PUBLIC_URL + "/noticeboard"}`} component={NoticeBoard} />
                <Route path={`${process.env.PUBLIC_URL + "/events"}`} component={Events} />
                <Route path={`${process.env.PUBLIC_URL + "/event-details"}`} component={EventDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/rules-admission"}`} component={Rules_Admission} />
                <Route path={`${process.env.PUBLIC_URL + "/rules-examination"}`} component={Rules_Examination} />
                <Route path={`${process.env.PUBLIC_URL + "/rules-of-conduct"}`} component={RulesOfConduct} />
                <Route path={`${process.env.PUBLIC_URL + "/rules-uniform"}`} component={RulesOfUniform} />
                <Route path={`${process.env.PUBLIC_URL + "/activities"}`} component={Activities} />
                
                <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact} />
                <Route path={`${process.env.PUBLIC_URL + "/404"}`} component={PageNotFound} />
                {/* <Route path={`${process.env.PUBLIC_URL + "/course-grid"}`} component={CourseGrid} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/course-details"}`} component={CourseDetails} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/instructor"}`} component={Instructor} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/instructor-details"}`} component={InstructorDetails} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/login"}`} component={Login} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/registration"}`} component={Register} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={Faq} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/coming-soon"}`} component={ComingSoon} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} component={BlogClassic} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} component={BlogGrid} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/blog-details"}`} component={BlogDetails} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/products"}`} component={Product} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/product-details"}`} component={ProductDetails} /> */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/cart"}`} component={Cart} /> */}
                {/* comment it on production */}
                {/* <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo} /> */}



            </Switch>
            <ScrollToHash />

        </Router>
    )
}

export default App;
 

function ScrollToHash() {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.replace("#", ""));
            if (element) {
                // Scroll to the element first
                element.scrollIntoView({ behavior: "smooth" });

                // Add a small delay to adjust the scroll position with an offset
                setTimeout(() => {
                    window.scrollBy({
                        top: -200, // Adjust this value to set your desired buffer
                        behavior: "smooth"
                    });
                }, 1000); // Adjust timeout if needed
            }
        }
    }, [hash]);

    return null;
}

 
