import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import { Styles } from "./styles/campusTour.js";
import { Link } from 'react-router-dom/cjs/react-router-dom.min.js';

const Data = {
    secTitle: "STUDENT LIFE GALLERY",
}

class CampusTour extends Component {
    // state = {
    //     secTitle: "We Have Best Campus In The Region. Let's Explore The Campus.",
    // }

    render() {
        return (
            <Styles>
                {/* Campus Tour */}
                <section className="campus-tour">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{Data.secTitle}</h4>
                                </div>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/1.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/1.jpg"}  alt="" />
                                </div>
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/2.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/2.jpg"}   alt="" />
                                </div>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/3.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/3.jpg"}  alt="" />
                                </div>
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/4.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/4.jpg"}   alt="" />
                                </div>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/13.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/13.jpg"}  alt="" />
                                </div>
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/16.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/16.jpg"}   alt="" />
                                </div>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/7.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/7.jpg"}  alt="" />
                                </div>
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/8.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/8.jpg"}   alt="" />
                                </div>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/9.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/9.jpg"}  alt="" />
                                </div>
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/10.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/10.jpg"}   alt="" />
                                </div>
                            </Col>
                            <Col lg="2" md="3">
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/11.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/11.jpg"}  alt="" />
                                </div>
                                <div className="tour-box">
                                    <ModalImage large={process.env.PUBLIC_URL + "/assets/custom_images/gallery/12.jpg"} small={process.env.PUBLIC_URL + "/assets/custom_images/gallery/12.jpg"}   alt="" />
                                </div>
                            </Col>
                           
                            
                        </Row>
                        <div className="text-center">
                            <Link to="/gallery">
                                <div className="gallery-btn2" role='button'> View more</div>
                            </Link>
                               
                            </div>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default CampusTour
