import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { db , doc, getDoc ,collection, query, limit, orderBy, startAfter, getDocs } from 'FirebaseConfig';

import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
// import CourseItemList from './components/CourseItemsList';
// import NoticeList from './components/NoticeList';
import Footer from 'components/Footer';
import { Styles } from './styles/course.js';

import { useParams } from 'react-router-dom';
import MarkdownView from 'react-showdown';

const NoticeBoardDetailed = () => {
    
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const { id } = useParams()

    useEffect(() => {
        fetchDataById();
    }, [id]);

    const fetchDataById = async () => {
        setLoading(true);
        try {
            console.log(id);
            const docRef = doc(db, 'noticeboard', id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                // console.log(docSnap.data());
                setData(docSnap.data());
            } else {
                console.error("No such document!");
            }
        } catch (error) {
            console.error("Error fetching document: ", error);
        }
        setLoading(false);
    };

     

    return (
        <div className="main-wrapper course-page">
            {/* Header 2 */}
            <HeaderTwo />
            {/* Breadcrumb */}
            <BreadcrumbBox title={data?.title} />
            <Styles>
                {/* Course Grid */}
                <section className="course-list-area">
                    <Container>
                        <MarkdownView
                            markup={data?.description}
                        />
                    </Container>
                </section>
            </Styles>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default NoticeBoardDetailed;
