import React from 'react';
import { Styles } from 'pages/instructor/styles/instructor';
import { Container, Row, Col } from 'react-bootstrap';
import { colors } from 'components/common/element/elements';
// import Datas from 'data/instructor/details.json';


function Message() {
    return (<div id="principal">
        <Styles>
            <div className="main-wrapper instructor-details-page">
                {/* Instructor Details Area */}
                <section id="administration" className="instructor-details-area">
                    <Container>
                        <Row>
                            
                            <Col md="12">
                                <div className="instructor-content">
                                    <h4>ADMINISTRATION</h4>
                                    {/* <span>Manager</span> */}
                                    <p>
                                    St. Mary's Convent School, Mariam Nagar, Ghaziabad is locally administered by the Santhome 
                                    Province of Congregation of the Charity Sisters of Francis Assisi under 
                                    the guidance and direction of a management and a principal appointed by the 
                                    provincial Superior of Santhome Province, Ghaziabad. 
                                    The Santhome Province of Congregation of the Charity Sisters 
                                    of Francis Assisi has various schools in different parts of India. 
                                    These religious sisters are voluntarily vowed to God to lead a holy, 
                                    simple and dedicated life of selfless service to their fellow human beings.
                                     In order to surrender themselves totally to the love of God and service of humanity 
                                     they renounce family life and live in religious communities named as convents, 
                                     a home of prayer and serenity.
                                    </p>
                                </div>
                                
                            </Col>

                        </Row>
                    </Container>
                </section>
                {/* Instructor Details Area */}
                <section style={{ background: colors.bg2 }} className="instructor-details-area">
                    <Container>
                        <Row>
                            <Col md="4">
                                <div className="_instructor-img d-flex  justify-content-md-center"  >
                                    <div style={{"borderRadius" : "30px", overflow : "hidden",}} className="">

                                        <img src={process.env.PUBLIC_URL + `/assets/custom_images/about/principal_leena.jpeg`} alt="" className="img-fluid" style={{width : "100%"}} />
                                    </div>

                                </div>
                            </Col>
                            <Col md="8">
                                <div className="instructor-content">
                                    <h4>Sr. Leena Kaitharath </h4>
                                    <span>Principal</span>
                                    <p>
                                        
                                        Dear Parents,
                                        <br/>
                                        <br/>
                                        Over the years St. Mary's Convent School has come to symbolize the spirit of dedication to the task of maintaining an excellent standard of education. I am fortunate to work with such dedicated, innovative and caring staff members, committed parents and vibrant students. I find each day an adventure filled with new possibilities and chances to constantly widen our horizons. It always gives me great pleasure to see a sea of smiling on the faces of children and I must admit that, they make my day lighter and brighter.
                                        <br/>
                                        <br/>
                                        Aristotle once said that, “Educating the mind without educating the heart is no education at all.” As we impart education to match the advancement in technology and globalization, we march our children ahead with ethos of moral values and principles. Our students are equipped to face the challenges of the rapidly changing world.
                                        <br/>
                                        <br/>
                                        The purpose of this Hand-book is to give parents, guardians, teachers and students a concise source of information on our school, its rules, goals and expectations. It gives the academic and co-curricular activities of the session and acts as a communication link between the School and Parents.
                                        <br/>
                                        <br/>
                                        I am sure through collaborative effort we can achieve more, to benefit our students who are the future leaders of tomorrow.
                                        <br/>
                                        <br/>
                                        Principal
                                        <br/>
                                        St. Mary's Convent School
                                        <br/>

                                        Mariam Nagar
                                        

                                    </p>
                                </div>

                            </Col>

                        </Row>
                    </Container>
                </section>
            </div>
        </Styles>
    </div>);
}

export default Message;