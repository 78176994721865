import React, {  useState, useEffect } from 'react';
// import Datas from '../../data/gallery/gallery-page.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
// import Pagination from './../../components/Pagination';
// import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/gallery.js';

import { db, collection, query, limit, orderBy, startAfter, getDocs } from 'FirebaseConfig';
import Footer from 'components/Footer';

const _SampleData = [
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/1.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/2.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/3.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/4.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/7.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/8.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/9.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/10.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/11.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/12.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/13.jpg"
    },
    {
        url :  process.env.PUBLIC_URL + "/assets/custom_images/gallery/14.jpg"
    },
]

const  Gallery =  () => {

    const [images, setImages] = useState([ ]);
    const [lastDoc, setLastDoc] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const load_limit = 6
    
    const fetchImages = async (lastDoc = null) => {
        setLoading(true);
        let queryRef;
    
        if (lastDoc) {
          queryRef = query(collection(db, 'images'), orderBy('createdAt'), startAfter(lastDoc), limit(load_limit));
        } else {
          queryRef = query(collection(db, 'images'), orderBy('createdAt'), limit(load_limit));
        }
    
        const querySnapshot = await getDocs(queryRef);
        const fetchedImages = querySnapshot.docs.map(doc => doc.data());
    
        setImages(prevImages => [...prevImages, ...fetchedImages]);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

     
    
        if ((querySnapshot.docs.length  ) < (load_limit)) {
          setHasMore(false);
        }
    
        setLoading(false);
      };

      useEffect(() => {
        fetchImages();
      }, []);
    
      const loadMore = () => {
        if (hasMore) {
          fetchImages(lastDoc);
        }
      };

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper gallery-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="Gallery" />

                    {/* Gallery Area */}
                    <section className="gallery-page-area">
                        <Container>
                            <Row>
                                {
                                    [..._SampleData , ...images].map((data, i) => (
                                        <Col lg="4" sm="6" key={i}>
                                            <div  className="gallery-box">
                                                <ModalImage small={data.url} large={data.url} alt="" />
                                            </div>
                                        </Col>
                                    ))
                                }

                                <Col md="12" className="text-center">
                                    {loading && <p className='h3 text-secondary' >Loading...</p>}
                                    {hasMore && !loading && <div className='btn1' onClick={loadMore}>Load More</div>}
                                    {/* {!hasMore && <p className='text-secondary'> _ _ _</p>} */}
                                    
                                    
                                    {/* <Pagination /> */}
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer 2 */}
                    <Footer />

                </div>
            </Styles>
        )
}

export default Gallery