import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import Footer from 'components/Footer';
import { Styles } from './styles/course.js';
import { colors } from 'components/common/element/elements';

const Rules = () => {


    return (
        <div className="main-wrapper course-page">
            <HeaderTwo />
            <Styles>
                {/* Course Grid */}
                <section className="course-list-area">
                    <div className="payment-area ">

                        <div id='summer' className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-3  pt-5 _text-center'> SUMMER UNIFORM </h5>
                                <div className='h5  pb-2   _text-center'> Boys: </div>

                                <ul className='pl-4 payment-ul '>

                                    <li className='pb-2' >
                                        Grey Trousers.
                                    </li>
                                    <li className='pb-2' >
                                        White shirt with half sleeves.
                                    </li>
                                    <li className='pb-2' >
                                        Grey socks and black shoes.
                                    </li>
                                    <li className='pb-2' >
                                        Grey tie and belt with the school's name.
                                    </li>

                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>

                    </div>
                    <div className="payment-area ">

                        <div id='summer' className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-3  pt-5 _text-center'> SUMMER UNIFORM </h5>
                                <div className='h5  pb-2   _text-center'> Girls: </div>

                                <ul className='pl-4 payment-ul '>

                                    <li className='pb-2' >
                                        Grey tunic.
                                    </li>
                                    <li className='pb-2' >
                                        White shirt with half sleeves.
                                    </li>
                                    <li className='pb-2' >
                                        Grey socks and black shoes.
                                    </li>
                                    <li className='pb-2' >
                                        Maroon ribbon.
                                    </li>
                                    <li className='pb-2' >
                                        Grey tie and belt with the school's name.
                                    </li>
                                    <li className='pb-2' >
                                        House T-shirt with grey skirt/trousers on Saturday and Wednesday is compulsory.
                                    </li>

                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>

                    </div>
                    <div className="payment-area ">

                        <div id='winter' className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-3  pt-5 _text-center'> WINTER UNIFORM </h5>
                                <div className='h5  pb-2   _text-center'> Boys: </div>

                                <ul className='pl-4 payment-ul '>

                                    <li className='pb-2' >
                                        Grey Pants.
                                    </li>
                                    <li className='pb-2' >
                                        White shirts with full sleeves.
                                    </li>
                                    <li className='pb-2' >
                                        Grey sweater (plain knitted and V-necked).
                                    </li>
                                    <li className='pb-2' >
                                        Grey socks and black shoes.
                                    </li>
                                    <li className='pb-2' >
                                        Grey tie and belt with the school's name.
                                    </li>
                                    <li className='pb-2' >
                                        Maroon cap.
                                    </li>

                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>

                    </div>
                    <div className="payment-area ">

                        <div id='winter' className="_bg-light ">
                            <Container>
                                <h5 className='payment-h5 pb-3  pt-5 _text-center'> WINTER UNIFORM </h5>
                                <div className='h5  pb-2   _text-center'> Girls: </div>

                                <ul className='pl-4 payment-ul '>

                                    <li className='pb-2' >
                                    Grey tunics; pants from 6th class.
                                    </li>
                                    <li className='pb-2' >
                                    White shirt with full sleeves.
                                    </li>
                                    <li className='pb-2' >
                                    Maroon blazers.
                                    </li>
                                    <li className='pb-2' >
                                    Grey sweater (maroon stripes and V-necked).
                                    </li>
                                    <li className='pb-2' >
                                    Grey and black shoes.
                                    </li>
                                    <li className='pb-2' >
                                    Grey tie and belt with the school's name.
                                    </li>
                                    <li className='pb-2' >
                                    Maroon scarf.
                                    </li>
                                    <li className='pb-2' >
                                    White tracksuit on Saturday & Wednesday.
                                    </li>

                                </ul>
                                <div className="pt-5">

                                    {/* <hr /> */}
                                </div>

                            </Container>
                        </div>

                    </div>
                </section>
            </Styles>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Rules;
