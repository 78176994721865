export const colors = {

    // bg1: "#182B49",
    bg1: "#146632",
    bg2: "#F6F9FF",
    bg3: "#fff8da",
    // bg2: "#66BB6A",
    // gr_bg: "linear-gradient(90deg, #66BB6A 0%, #009444 100%)",
    gr_bg: "linear-gradient(90deg, #ffd900 0%, #ffd900 100%)",
    gr_bg2: "linear-gradient(90deg, #009444 0%, #66BB6A 100%)",
    // copy_bg: "#122340",
    copy_bg: "#0a3319",
    // blue: "#2c97ea",
    blue: "#2c97ea",
    // green: "#11B67A",
    green: "#66BB6A",
    green2: "#00a76a",
    red: "#ff655a",
    // purple: "#84479c",
    purple: "#2258c0",
    yellow: "#fbab19",
    yellow2: "#ffd900",
    black1: "#182B49",
    black2: "#444444",
    text1: "#555555",
    text2: "#666666",
    text3: "#969696",
    text4: "#aaaaaa",
    text5: "#cccccc",
    border1: "#eeeeee",
    border2: "#3e3e3e",
    border3: "#dddddd",
    footer1: "#1a1b25",
    footer2: "#16171f",
    ftext: "#8D8E92",

}

export const fonts = {

    roboto: "'Roboto', sans-serif",
    poppins: "'Poppins', sans-serif",

}