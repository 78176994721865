import React, { useEffect, useState } from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo.js';
import { BreadcrumbBox } from '../../components/common/Breadcrumb.js';
// import ReviewForm from './components/ReviewForm.js';
// import PopularCourse from './components/PopularCourse.js';
// import CourseTag from './components/CourseTag.js';
// import FooterTwo from '../../components/FooterTwo.js';
import { Styles } from './styles/course.js';

import { db, collection, query, orderBy, getDocs } from 'FirebaseConfig';
import Footer from 'components/Footer.js';


function CourseDetails() {

    const [achievements, setAchievements] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);
    // const [loadingMore, setLoadingMore] = useState(false);
    // const [hasMore, setHasMore] = useState(true);
    // const [noMoreRecords, setNoMoreRecords] = useState(false);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        setLoading(true);
        try {
            const achievmentsQuery = query(
                collection(db, 'downloads'),
                orderBy('ac_date', 'desc'),
                // limit(5)
            );
            const querySnapshot = await getDocs(achievmentsQuery);
            const achievementsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAchievements(achievementsData);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching achievements: ", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const courseButton = document.querySelectorAll(".course-button");
        courseButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "course-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "course-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });

    return (
        <div className="main-wrapper course-details-page" >

            {/* Header 2 */}
            < HeaderTwo />

            {/* Breadcroumb */}
            < BreadcrumbBox title="Downloads" />

            <Styles>
                {/* Course Details */}
                <section className="course-details-area">
                    <Container>
                        {loading ? <div className="text-center">Loading...</div> :
                            <div>
                                {
                                    achievements.length > 0 ?
                                        (
                                            <Row>
                                                <Col sm="12">
                                                    <div className="course-details-top">


                                                        <div className="course-tab-list">
                                                            <div defaultActiveKey="curriculum">

                                                                <div className='tab-content'>

                                                                    <div eventKey="curriculum" className="curriculum-tab">

                                                                        <div className="course-element">

                                                                            <div className="course-item">
                                                                                <div className="course-content show">
                                                                                    <ul className="list-unstyled">
                                                                                        {achievements.map((item, index) => {
                                                                                            return (
                                                                                                <a href={item.link} target='_blank'>

                                                                                                    <li key={index} className='clickable_div p-2'>
                                                                                                        {/* <a href={item.link} target='_blank'> */}

                                                                                                        <span className="play-icon"><i className="las la-file-download"></i> </span>
                                                                                                        {/* </a> */}
                                                                                                        <span className="lecture-title"> {item.title} </span>
                                                                                                        <span className="lecture-duration">
                                                                                                            {/* <a href={item.link} target='_blank'> */}
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill='green' width="24" height="24" viewBox="0 0 24 24"><path d="M12 21l-8-9h6v-12h4v12h6l-8 9zm9-1v2h-18v-2h-2v4h22v-4h-2z" /></svg>
                                                                                                            {/* </a> */}
                                                                                                        </span>
                                                                                                    </li>
                                                                                                </a>
                                                                                            )
                                                                                        })}

                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>
                                        )
                                        :
                                        (<div style={{ minHeight: "200px" }} className="pt-5 pb-5 w-100 d-flex justify-content-center" >
                                            <div className=" " style={{ opacity: "0.5" }}>
                                                <img style={{ maxWidth: "100px", opacity: "0.5" }} src="/assets/custom_images/achievments/icon_blank.png" />
                                                <div className="text-center pt-3  font-weight-bold">
                                                    Nothing to display
                                                </div>
                                            </div>
                                        </div>)
                                }

                            </div>
                        }


                    </Container>
                </section>
            </Styles>

            {/* Footer 2 */}
            <Footer />

        </div >
    )
}

export default CourseDetails