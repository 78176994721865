import React, { Component } from 'react';
// import Datas from '../data/about-us/about-us.json';
// import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
// import ModalVideo from 'react-modal-video';
// import CountUp from 'react-countup';
import { Styles } from "./styles/aboutUs.js";
// import IconBox from './IconBox.js';

const Datas = {
    "mainImage": "/assets/custom_images/about/about.jpeg",
    "videoBackground": "vd-bg.jpg",
    "title": "ABOUT US",

    // "desc1": `St. Mary's Convent School is run by the Santhome Province of Congregation of the Sisters of Charity. We believe that Education is not just a preparation of life, but life itself. Therefore together with knowledge, we take utmost care in character and conscience formation of the every child. St. Mary’s Convent School is opened to all the children irrespective of caste and creed; they are accepted and cherished as they are and are helped to grow in their cultural, social and religious traditions.
    // `,
    "desc1": `
    St. Mary's Convent School, Mariam Nagar, Ghaziabad was established in the year 2017 and run by the Santhome Province of Congregation of the Sisters of Charity. Education is not preparation for life, education is life itself. Intelligence plus character, i.e., the goal of true education. St. Mary's Convent School is opened to all the children irrespective of caste and creed; they are accepted and cherished as they are and are helped to grow in their cultural, social and religious traditions.
    `,
   
    // "desc2": "To encourage positive thinking, constructive words and creative action. To promote physical, emotional and healthy community relationship."
    "desc2": "We aim at forming leaders who are intellectually competent, spiritually mature, morally upright, psychologically integrated, physically healthy and socially acceptable; who will champion the cause of justice, love, wisdom, truth and peace and who are open to further growth.",

    "desc3": "The secret of the success of our educational institutions is a community of teachers who are committed to their job, professionally competent, morally upright, just and humane in dealings, and who grow in the true vision of education."
}

class AboutUs extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <Styles>
                {/* About Us */}
                <section className="about-us">
                    <Container>
                        <Row>
                            <Col md="6">
                                <div className="about-image">
                                    <img src={process.env.PUBLIC_URL + `${Datas.mainImage}`} className="main-img" alt="" />
                                    
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="about-content">
                                    <h4 className="about-title">{Datas.title}</h4>
                                    <p className="about-para">{Datas.desc1}
                                    <h5 id='objective' className='' style={{paddingTop : "20px"}}> Aim & Objectives </h5>
                                    <span >{Datas.desc2}</span> 
                                    <span>{Datas.desc3}</span></p>
                                     
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default AboutUs
